import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { YMInitializer } from "react-yandex-metrika";
import App from "./App";
import "./index.css";
import "./instrument.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <YMInitializer accounts={[96110260]} />
    <App />
  </HashRouter>
);
